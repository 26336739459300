import { useI18n } from 'vue-i18n';
import { computed, watch } from 'vue';
import { roundUsdNumber } from '@/helpers/utils';
import {
  getLeverageItems,
  getPercentAmountItems,
} from '@/modules/formPosition/use/commonSimple';
import useMaxAmount from '@/compositions/useMaxAmount';
import { POSITION_OPEN_MIN_VAL } from '@/helpers/enums';
import notify from '@/plugins/notify';
import { useFiorinBalanceNull } from '@/compositions/useFiorinBalanceNull';
import debounce from 'lodash/debounce';
import auth from '@/store/modules/auth/auth';
import { RISK_MANAGEMENT } from '@/modules/settings/store';
import { PositionSides } from '@/config/constants';

export const useFormData = (store) => {
  const leverage = computed(() => store.getters['formPosition/leverage']);
  const maxAmountMain = useMaxAmount(leverage);

  const enableToOpenPosition = computed(() => {
    return maxAmountMain.enableToOpenPosition.value;
  });

  const maxPositionAmountUsd = computed(() => {
    return maxAmountMain.maxPositionAmountUsd.value?.amount || 0;
  });

  const amountPercent = computed(
    () => store.getters['formPosition/amountPercent']
  );

  const amountUsd = computed(() => {
    const value = store.getters['formPosition/amountUsd'];
    return value;
  });

  const marginUsd = computed(() => {
    return amountUsd.value / leverage.value;
  });

  const entryPrice = computed(() => store.getters['formPosition/entryPrice']);

  const takeProfit = computed(() => store.getters['formPosition/takeProfit']);
  const stopLoss = computed(() => store.getters['formPosition/stopLoss']);

  const getRiskManagement = computed(() => {
    return store.getters['settings/uiSetting']('riskManagement') ===
      RISK_MANAGEMENT.PERCENT
      ? RISK_MANAGEMENT.PERCENT
      : RISK_MANAGEMENT.PRICE;
  });

  const riskInPercents = computed(
    () => getRiskManagement.value === RISK_MANAGEMENT.PERCENT
  );

  const activeMarket = computed(
    () => store.getters['formPosition/activeMarket'] || {}
  );

  const positionType = computed(() => {
    return store.getters['formPosition/positionType'];
  });

  const stopLossPercent = computed(() => {
    if (stopLoss.value === 0) {
      return 0;
    }

    if (!stopLoss.value || +stopLoss.value < 0) {
      return '';
    }

    if (riskInPercents.value) {
      return +stopLoss.value >= 0 ? (+stopLoss.value).toFixed(2) : '';
    }

    return (
      positionType.value === PositionSides.BUY
        ? leverage.value *
          (1 - stopLoss.value / (entryPrice.value || +activeMarket.value.buy)) *
          100
        : leverage.value *
          (stopLoss.value / (entryPrice.value || +activeMarket.value.sell) -
            1) *
          100
    ).toFixed(2);
  });

  const takeProfitPercent = computed(() => {
    if (!takeProfit.value) {
      return '';
    }

    if (riskInPercents.value) {
      return takeProfit.value.toFixed(2);
    }

    return (
      positionType.value === PositionSides.BUY
        ? leverage.value *
          (takeProfit.value / (entryPrice.value || +activeMarket.value.buy) -
            1) *
          100
        : leverage.value *
          (1 -
            takeProfit.value / (entryPrice.value || +activeMarket.value.sell)) *
          100
    ).toFixed(2);
  });

  const formComputed = computed(() => {
    return {
      entryPrice: entryPrice.value,
      amountUsd: amountUsd.value,
      leverage: leverage.value,
      marginUsd: marginUsd.value,
      takeProfit: takeProfit.value,
      takeProfitPercent: takeProfitPercent.value,
      stopLoss: stopLoss.value,
      stopLossPercent: stopLossPercent.value,
      positionType: positionType.value,
      percentSelectorAmount: amountPercent.value,
    };
  });

  const printAll = (label) => {
    const allFromState = {
      entryPrice: store.getters['formPosition/entryPrice'],
      amountUsd: store.getters['formPosition/amountUsd'],
      percentSelectorAmount: store.getters['formPosition/amountPercent'],
      leverage: store.getters['formPosition/leverage'],
      marginUsd: store.getters['formPosition/marginUsd'],
      takeProfit: store.getters['formPosition/takeProfit'],
      stopLoss: store.getters['formPosition/stopLoss'],
    };

    console.debug(
      label,
      '\n',
      '\n',
      'printAll #formComposition',
      '\n',
      '\n',
      'formData:',
      formComputed.value,
      '\n',
      '\n',
      'allFromState:',
      allFromState
    );
  };

  const isExpanded = computed(() => {
    return store.getters['formPosition/isExpanded'];
  });

  const tpslError = computed(() => store.getters['formPosition/hasError']);

  return {
    form: {
      isExpanded,
      positionType,
      entryPrice,
      leverage,
      amountUsd,
      amountPercent,
      marginUsd,
      takeProfit,
      takeProfitPercent,
      stopLoss,
      stopLossPercent,
      tpslError,
    },

    riskInPercents,
    formComputed,
    printAll,
    enableToOpenPosition,
    maxPositionAmountUsd,
    maxAmountMain,
    activeMarket,
  };
};

export const usePositionForm = (store) => {
  const { t } = useI18n();
  const { isFiorinNullableBalance } = useFiorinBalanceNull();

  const allFormData = useFormData(store);

  const {
    maxPositionAmountUsd,
    maxAmountMain,
    activeMarket,
    form: { leverage, amountUsd, amountPercent },
  } = allFormData;

  const maxWalletValue = computed(() => {
    return maxAmountMain.maxWalletValue.value;
  });

  const optionalLeverageBtnTitle = computed(() => {
    return `${leverage.value}X`;
  });

  const optionalAmountBtnTitle = computed(() => {
    if (!amountPercent.value) {
      return `X%`;
    }

    return `${amountPercent.value == 100 ? 'MAX' : amountPercent.value + '%'}`;
  });

  const notMoreThanBalance = (amountUsd, onTooBig) => {
    if (amountUsd > maxPositionAmountUsd.value) {
      console.warn(
        'Amount exceeds balance #formComposition',
        '\n',
        'amountUsd:',
        amountUsd,
        'type:',
        typeof amountUsd,
        'maxPositionAmountUsd:',
        maxPositionAmountUsd.value
      );

      if (onTooBig) {
        onTooBig();
      }

      amountUsd = maxPositionAmountUsd.value;
    }

    return amountUsd;
  };

  const fixAmountUsd = () => {
    let newAmountUsd = amountUsd.value;
    if (amountPercent.value) {
      newAmountUsd = (+maxPositionAmountUsd.value * +amountPercent.value) / 100;
      newAmountUsd = roundUsdNumber(newAmountUsd);
    }

    newAmountUsd = notMoreThanBalance(newAmountUsd);
    if (newAmountUsd !== amountUsd.value) {
      store.dispatch('formPosition/setAmountUsd', {
        amountUsd: newAmountUsd,
        from: 'fixAmountUsd',
      });
    }
  };

  watch(
    () => [
      amountPercent.value,
      amountUsd.value,
      maxPositionAmountUsd.value,
      activeMarket.value,
    ],
    debounce(fixAmountUsd, 200)
  );

  const setAmountPercent = async (percent) => {
    console.debug('#setAmountPercent #formComposition', 'percent:', percent);
    percent = percent?.title || percent?.value?.title || amountPercent.value;
    await store.dispatch('formPosition/setAmountPercent', percent);
  };

  const setAmountUsd = async (amountUsd) => {
    if (amountUsd) {
      amountUsd = +amountUsd;
    }

    if (amountUsd < 0) {
      console.error(
        'Why pass negative amountUsd? #trySetAmountUsd #formComposition',
        '\n',
        'amountUsd:',
        amountUsd
      );

      amountUsd = 0;
    }

    if (amountUsd) {
      amountUsd = notMoreThanBalance(amountUsd, () => {
        const toastText =
          maxWalletValue.value < POSITION_OPEN_MIN_VAL
            ? t('order.new.toasts.depleted', {
                provider: auth.provider.value,
              })
            : t('order.new.toasts.maxLimit');

        notify({
          text: toastText,
          type: 'info',
        });
      });
    }

    await store.dispatch('formPosition/setAmountUsd', {
      amountUsd,
      clearAmountPercent: true,
      from: 'set.setAmountUsd',
    });
  };

  const setEntryPrice = async (entryPrice) => {
    console.debug('#setEntryPrice #formComposition', 'entryPrice:', entryPrice);
    await store.dispatch('formPosition/setEntryPrice', entryPrice);
  };

  const setLeverage = async (leverage) => {
    console.debug('setLeverage #formComposition', 'leverage:', leverage);

    leverage = leverage?.title || leverage;
    await store.dispatch('formPosition/setLeverage', leverage);
  };

  const setTakeProfit = async (takeProfit) => {
    console.debug('#setTakeProfit #formComposition', 'takeProfit:', takeProfit);
    await store.dispatch('formPosition/setTakeProfit', takeProfit);
  };

  const setStopLoss = async (stopLoss) => {
    console.debug('#setStopLoss #formComposition', 'stopLoss:', stopLoss);
    await store.dispatch('formPosition/setStopLoss', stopLoss);
  };

  const setIsExpanded = (isExpandedMode) => {
    store.commit('formPosition/setIsExpanded', isExpandedMode);
  };

  const setIsFormShown = (isShown) => {
    store.commit('formPosition/setIsFormShown', isShown);
  };

  const setHasError = async (hasError) => {
    await store.dispatch('formPosition/setHasError', hasError);
  };

  const leverageItems = computed(() =>
    getLeverageItems(activeMarket.value?.max_leverage)
  );
  const percentAmountItems = getPercentAmountItems();

  const accountBalance = computed(
    () => store.getters['user/getAccountBalance']
  );

  return {
    ui: {
      leverageItems,
      percentAmountItems,
      optionalLeverageBtnTitle,
      optionalAmountBtnTitle,
    },

    ...allFormData,

    set: {
      setEntryPrice,
      setAmountUsd,
      setLeverage,
      setTakeProfit,
      setStopLoss,
      setAmountPercent,
      setIsExpanded,
      setIsFormShown,
      setHasError,
    },

    accountBalance,
    maxPositionAmountUsd,
    maxWalletValue,
    activeMarket,
    isFiorinNullableBalance,
  };
};

export const useFormVisibility = (store) => {
  const setIsExpanded = (isExpandedMode) => {
    store.commit('formPosition/setIsExpanded', isExpandedMode);
  };

  const setIsFormShown = (isShown) => {
    if (store.getters['formPosition/isFormShown'] !== isShown) {
      store.commit('formPosition/setIsFormShown', isShown);
    }
  };

  const isExpandedAndShown = computed(() => {
    return (
      store.getters['formPosition/isFormShown'] &&
      store.getters['formPosition/isExpanded']
    );
  });

  const isFormShown = computed(() => store.getters['formPosition/isFormShown']);

  const hideForm = () => {
    setIsFormShown(false);
  };

  return {
    setIsExpanded,
    setIsFormShown,
    hideForm,
    isExpandedAndShown,
    isFormShown,
  };
};
