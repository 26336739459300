<template>
  <div>
    <div v-if="isBounty">
      <BountyButton
        :loading="bountyTxLoading"
        :margin-btn-value="marginInTokenToDisplay"
        :fiorin-mode="$isFiorinMode"
        @onClick="openBountyPosition"
      />
    </div>
    <div v-else>
      <div v-if="$isFiorin">
        <!-- FIORIN -->
        <HandCash
          :loading="walletTxLoading"
          :margin-btn-value="marginInTokenToDisplay"
          :currencyLabel="activeTokenName"
          @onClick="openFiorinPosition"
        />
      </div>
      <div v-if="$isHandCash">
        <HandCash
          :loading="walletTxLoading"
          :margin-btn-value="marginInTokenToDisplay"
          currencyLabel="BSV"
          @onClick="openHandCashPosition"
        />
      </div>
      <!--      <div v-if="$isDotWallet" style="width: 210px">-->
      <!--        <DotwalletButton-->
      <!--          :address="to"-->
      <!--          :amount="+marginBsv"-->
      <!--          :opreturn="transactionKey.toHex()"-->
      <!--          product-name="Position"-->
      <!--          @payment="onPayment"-->
      <!--          @error="$emit('error')"-->
      <!--        />-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
/* eslint-ignore */
import { mapGetters } from 'vuex';
import { defineComponent, computed } from 'vue';
import { connApp, getErrorDetails } from '@/helpers/signalR';
import HandCash from '@/components/HandCashButton';
import BountyButton from '@/components/BountyButton';
// import DotwalletButton from '@/components/DotwalletButton/DotwalletButton';
import { proposeBountyPosition } from '@/modules/bounty/api';
import authMixin from '@/mixins/auth.mixin';
import { useStore } from 'vuex';

import { paymentUID, toSentenceCase, trim } from '@/helpers/utils';
import envConfig from '@/config';
import notify from '@/plugins/notify';
import { PositionSides } from '@/config/constants';
import bsvPrice from '@/compositions/bsvPrice';
import useUserActions from '@/compositions/useUserActions';
import { NullDataBuilder } from 'dxs-stas-sdk/dist/script/build/null-data-builder';
import store from '@/store';
import useAuth from '@/compositions/useAuth';

export default defineComponent({
  mixins: [authMixin],
  setup(_, { emit }) {
    const store = useStore();
    const auth = useAuth();
    const userComposition = useUserActions();
    const activeTokenName = computed(() => {
      if (auth.isFiorin.value) {
        return store.getters['tokenSelector/collateralToken/name'];
      }

      return '';
    });

    const onSuccess = () => {
      if (
        userComposition.isHandCashProfile.value &&
        !userComposition.isHasEverOpenedPosition.value
      ) {
        userComposition.notifyHandCashBalance();
      }
    };

    return { emit, onSuccess, activeTokenName };
  },
  emits: ['error'],
  components: {
    HandCash,
    BountyButton,
    // DotwalletButton,
  },
  props: {
    type: {
      type: String,
      default: PositionSides.BUY,
    },
    to: {
      type: String,
      default: '',
    },
    amountUsd: {
      required: true,
      type: [Number],
    },

    takeProfit: {
      required: false,
      type: [Number],
    },

    stopLoss: {
      required: false,
      type: [Number],
    },

    entryPrice: {
      required: false,
      type: [Number],
    },

    marginInTokenToDisplay: {
      required: true,
      type: [String],
    },

    marginInTokenToPay: {
      required: true,
      type: [Number],
    },
    isBounty: Boolean,
  },
  data() {
    return {
      walletTxLoading: false,
      bountyTxLoading: false,
      defaultCurrency: 'BSV',
      marketId: null,
    };
  },
  computed: {
    ...mapGetters({
      activeMarketId: 'markets/activeMarketId',
      activeMarket: 'markets/activeMarket',
      marketsList: 'markets/markets',
      paymail: 'wallet/paymail',
      fiorinLastTx: 'fiorin/fiorinLastTx',
      tokenList: 'fiorin/activeTokens',
    }),

    tokenId() {
      if (this.$isFiorin) {
        return store.getters['tokenSelector/collateralToken/tokenId'];
      }

      return null;
    },

    msg() {
      const clean = (value) => {
        try {
          return value.toString().replace(/\s/g, '');
        } catch (e) {
          console.error(
            'Error in clean function #formPostitionConfirmPayment',
            e,
            'value:',
            value
          );
        }
      };

      let msg =
        paymentUID() +
        ',m' +
        this.marketId +
        ',t' +
        (this.type === PositionSides.BUY ? 'b' : 's') +
        ',a' +
        clean(this.amountUsd);

      const add = (key, value) => {
        if (value) {
          // value - replace whitespaces with empty string
          msg += ',' + key + clean(value);
        }
      };

      add('e', this.entryPrice);

      // [Vadim] allowed to set stopLoss to 0?
      // do not merge with the next condition
      const stopLossIsEmpty =
        this.stopLoss === null ||
        this.stopLoss === undefined ||
        this.stopLoss === '' ||
        isNaN(this.stopLoss);

      if (!stopLossIsEmpty && this.stopLoss >= 0) {
        msg += ',s' + clean(this.stopLoss);
      }

      add('p', this.takeProfit);

      return msg;
    },

    transactionKey() {
      return new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(this.msg),
        Buffer.from('text'),
        Buffer.from('B'),
      ]);
    },
  },
  created() {
    this.marketId = this.activeMarketId;
  },

  methods: {
    async openBountyPosition() {
      if (this.bountyTxLoading) {
        return;
      }
      if (!this.paymail) {
        notify({ text: 'Paymail not found', type: 'info' });
        return;
      }

      this.bountyTxLoading = true;
      const msg = `${this.msg},d${this.paymail}`;
      const txMsg = new NullDataBuilder([
        Buffer.from(envConfig.txPrefix),
        Buffer.from(msg),
        Buffer.from('text'),
        Buffer.from('B'),
      ]).toHex();

      console.debug('#proposeBountyPosition msg:', msg);

      const result = await proposeBountyPosition(
        txMsg,
        this.marginInTokenToPay
      ).catch((err) => {
        notify({ text: getErrorDetails(err).message, type: 'info' });
      });

      notify({
        text: this.$t('order.new.toasts.opening'),
        type: 'info',
        group: 'opened',
      });

      if (this.$isFiorinMode) {
        this.bountyTxLoading = false;
        this.onSuccess();
        this.emit('payment');
        return;
      }
      if (result.txId) {
        await this.onPayment({
          txid: result.txId,
          msg,
          isBounty: true,
        });
        this.bountyTxLoading = false;
      }
      if (result.error) {
        this.bountyTxLoading = false;
        notify({
          text: result.error.message || 'Bounty wallet: unknown pay error',
          type: 'info',
        });
      }
    },
    async openFiorinPosition() {
      if (!this.msg) {
        notify({
          text: 'Message error. Try again later.',
          type: 'info',
        });
        return;
      }
      const msg = `${envConfig.txPrefix} ${this.msg} text B`;

      if (this.walletTxLoading) {
        return;
      }

      this.walletTxLoading = true;

      console.log(
        'REFILL LOG #openFiorinPosition',
        this.activeTokenName,
        this.tokenId,
        this.marginInTokenToPay,
        '\n',
        'msg:',
        msg
      );

      let amountToPay = this.marginInTokenToPay;

      try {
        const txId = await store.dispatch(
          'fiorin/refill',
          {
            tokenId: this.tokenId,
            amount: amountToPay + '',
            message: msg,
            type: 'ACCOUNT',
          }
          // payload: { amount: +this.marginBsv, message: msg, type: 'ACCOUNT' },
        );

        if (txId) {
          await this.onPayment({ txid: txId, isFiorin: true });
          this.walletTxLoading = false;
        } else {
          notify({
            text: 'Wallet error',
            type: 'info',
          });
        }
      } catch (e) {
        notify({
          text: `Wallet error: ${e.message ?? e}`,
          type: 'info',
        });
      }
    },

    async openHandCashPosition() {
      if (!this.msg) {
        notify({
          text: 'Message error. Try again later.',
          type: 'info',
        });
        return;
      }
      if (this.walletTxLoading) {
        return;
      }
      this.walletTxLoading = true;

      console.log('#openHandCashPosition', 'msg:', this.msg);

      const msg = `${envConfig.txPrefix} ${this.msg} text B`
        .split(' ')
        .map((item) => Buffer.from(item).toString('hex'));

      console.log('marginInToken final save', +this.marginInTokenToPay);

      const result = await store.dispatch('handcash/sendHandCashTx', {
        msg,
        to: this.to,
        amount: +this.marginInTokenToPay,
        description: trim(
          `${toSentenceCase(this.type)} $${this.amountUsd} of ${
            this.activeMarket.name
          }`,
          25
        ), // HC limit - 25 characters
      });

      if (!result) {
        console.error('Got null after sendHandCashTx');
      }

      if (result.transactionId) {
        await this.onPayment({
          txId: result.transactionId,
        });
        this.walletTxLoading = false;
      }

      if (result.error) {
        this.walletTxLoading = false;
        notify({
          text: result.error.message || 'Wallet error',
          type: 'info',
        });
      }
    },
    async onPayment(data) {
      try {
        const payload = {
          bsvPrice: bsvPrice(),
          tokenId: this.tokenId || '',
          TransactionId: data.txid || data.txId,
          TransactionMessage: data.msg || this.msg,
          TransactionAmount: this.marginInTokenToPay,
          IsBounty: data.isBounty || false,
        };

        console.debug('calling #onPayment, payload:', payload);

        // todo: [Vadim] temporary removed await, in order to avoid the blocking of the UI,
        // but waiting for the fix on the server side to get await back
        //await
        connApp.invokeImmediately('ProposeUserPosition', payload);

        notify({
          text: this.$t('order.new.toasts.opening'),
          type: 'info',
          group: 'opened',
        });

        this.onSuccess();
        this.emit('payment');
      } catch (error) {
        console.error('onPayment error', JSON.stringify(error));
        this.emit('error');
        // notify({
        //   text: `Check pay parameters. ${e}`,
        //   type: 'info',
        // });
      }
    },

    onError() {
      this.emit('error');
    },
  },
});
</script>
